import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage, CopyrightFooter } from "./pages/HomePage";
import DocsPage from "./pages/DocsPage";
import ContactPage from "./pages/ContactPage";
import NavBar from "./components/NavBar";
import PulsePage from "./pages/PulsePage";
import PulseDemoPage from "./pages/PulseDemoPage";
import NuclearPage from "./pages/NuclearPage";
import ExlaStorePage from "./pages/ExlaStorePage";
import EdgeAgentHubPage from "./pages/EdgeAgentHubPage";

function App() {
  return (
    <Router>
      <div className="app">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/docs" element={<DocsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/pulsedemo" element={<PulseDemoPage />} />
          <Route path="/pulse" element={<PulsePage />} />
          <Route path="/nuclear" element={<NuclearPage />} />
          <Route path="/blobstore" element={<ExlaStorePage />} />
          <Route path="/edge-agent-hub" element={<EdgeAgentHubPage />} />
        </Routes>
        <CopyrightFooter />
      </div>
    </Router>
  );
}

export default App;
