import React, { useState } from "react";

function EdgeAgentHubPage() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const industries = [
    {
      title: "Manufacturing Plants",
      cases: [
        {
          title: "Predictive Maintenance",
          description:
            "Connect sensors like vibration and temperature monitors on your assembly line. Our platform processes the data locally to predict and prevent failures, reducing costly downtime.",
        },
        {
          title: "Quality Control",
          description:
            "Integrate vision sensors to detect defects in real time. Transform raw visual data into actionable insights for immediate corrective action.",
        },
      ],
    },
    {
      title: "Robotics Companies",
      cases: [
        {
          title: "Real-Time Sensor Fusion",
          description:
            "Enable robots to process data from multiple sensors locally, adapting to environmental changes instantly for precise operations.",
        },
        {
          title: "Fleet Coordination",
          description:
            "Manage a network of robots by sharing insights across devices, optimizing workloads, and increasing operational efficiency.",
        },
      ],
    },
    {
      title: "Energy and Utilities",
      cases: [
        {
          title: "Infrastructure Monitoring",
          description:
            "Monitor equipment like transformers and pipelines with diverse sensors. Detect anomalies locally for faster response and reduced costs.",
        },
        {
          title: "Energy Optimization",
          description:
            "Utilize edge-based processing to balance power loads and optimize energy consumption across your infrastructure.",
        },
      ],
    },
    {
      title: "Automotive Assembly",
      cases: [
        {
          title: "Assembly Line Optimization",
          description:
            "Ensure robotic arms and conveyors synchronize perfectly. Optimize operations with seamless data sharing and real-time adjustments.",
        },
        {
          title: "Quality Assurance",
          description:
            "Verify part installations using computer vision on-device, flagging defects immediately to maintain production standards.",
        },
      ],
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % industries.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + industries.length) % industries.length
    );
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {/* Header Section */}
      <header
        style={{
          textAlign: "center",
          marginBottom: "40px",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            fontWeight: "bold",
            color: "#2c5282",
            marginBottom: "20px",
          }}
        >
          Exla Edge Agent Platform
        </h1>
        <p
          style={{
            fontSize: "1.5rem",
            color: "#333",
            marginBottom: "15px",
            maxWidth: "800px",
            margin: "0 auto",
            lineHeight: "1.5",
          }}
        >
          Connect. Process. Act.
        </p>
        <div
          style={{
            textAlign: "left",
            maxWidth: "800px",
            margin: "0 auto",
            padding: "10px",
            fontSize: "1.2rem",
            lineHeight: "1.8",
            color: "#555",
          }}
        >
          <h3
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "#2c5282",
              marginBottom: "10px",
            }}
          >
            What We Provide:
          </h3>
          <ul
            style={{
              listStyleType: "disc",
              paddingLeft: "20px",
            }}
          >
            <li>
              Edge Agentic Hub: A unified platform for seamless integration of
              devices and sensors.
            </li>
            <li>
              Effortless connectivity with sensors, cameras, robotics
              controllers, and analytics tools.
            </li>
            <li>
              Real-time data processing to generate actionable insights at the
              edge, enabling smarter and faster decisions.
            </li>
          </ul>
        </div>
      </header>

      {/* Industry Solutions Section */}
      <section style={{ marginBottom: "60px" }}>
        <h2
          style={{
            fontSize: "1.8rem",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          Industry Solutions
        </h2>

        {/* Carousel Navigation */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          {industries.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                border: "none",
                backgroundColor: currentSlide === index ? "#2c5282" : "#e2e8f0",
                cursor: "pointer",
                padding: 0,
                margin: "0 4px",
              }}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>

        {/* Carousel Container */}
        <div style={{ position: "relative" }}>
          {/* Previous Button */}
          <button
            onClick={prevSlide}
            style={{
              position: "absolute",
              left: "-40px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "#2c5282",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            ←
          </button>

          {/* Slide Content */}
          <div
            style={{
              padding: "25px",
              backgroundColor: "#f8f9fa",
              borderRadius: "12px",
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <h3
              style={{
                color: "#2c5282",
                marginBottom: "15px",
                fontSize: "1.4rem",
                textAlign: "center",
              }}
            >
              {industries[currentSlide].title}
            </h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              {industries[currentSlide].cases.map((useCase, index) => (
                <div key={index}>
                  <h4 style={{ color: "#4a5568", marginBottom: "10px" }}>
                    {useCase.title}
                  </h4>
                  <p style={{ color: "#555", lineHeight: "1.6" }}>
                    {useCase.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Next Button */}
          <button
            onClick={nextSlide}
            style={{
              position: "absolute",
              right: "-40px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "#2c5282",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            →
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section style={{ marginBottom: "40px" }}>
        <h2 style={{ fontSize: "1.8rem", marginBottom: "20px" }}>
          Why Choose Exla Edge Agent Hub?
        </h2>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div
            style={{
              padding: "20px",
              border: "1px solid #eee",
              borderRadius: "8px",
            }}
          >
            <h3 style={{ color: "#2c5282", marginBottom: "10px" }}>
              Local Intelligence
            </h3>
            <p>
              Process data locally to generate immediate, actionable insights
              without relying on cloud connectivity.
            </p>
          </div>
          <div
            style={{
              padding: "20px",
              border: "1px solid #eee",
              borderRadius: "8px",
            }}
          >
            <h3 style={{ color: "#2c5282", marginBottom: "10px" }}>
              Seamless Integration
            </h3>
            <p>
              Connect any device—sensors, cameras, or robotics controllers—and
              unify data streams effortlessly.
            </p>
          </div>
          <div
            style={{
              padding: "20px",
              border: "1px solid #eee",
              borderRadius: "8px",
            }}
          >
            <h3 style={{ color: "#2c5282", marginBottom: "10px" }}>
              Centralized Monitoring
            </h3>
            <p>
              Monitor and control all connected devices through a secure,
              intuitive dashboard.
            </p>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section style={{ textAlign: "center", marginTop: "40px" }}>
        <h2 style={{ fontSize: "1.8rem", marginBottom: "20px" }}>
          Unlock the Power of the Edge
        </h2>
        <p
          style={{
            marginBottom: "20px",
            fontSize: "1.1rem",
            color: "#555",
          }}
        >
          Experience a game-changing solution that connects, manages, and
          optimizes your edge devices for real-time decision-making.
        </p>
        <a
          href="mailto:contact@exla.ai"
          style={{
            display: "inline-block",
            padding: "12px 24px",
            backgroundColor: "#2c5282",
            color: "white",
            textDecoration: "none",
            borderRadius: "4px",
            fontWeight: "bold",
          }}
        >
          Contact us to learn more
        </a>
      </section>
    </div>
  );
}

export default EdgeAgentHubPage;
